::-webkit-scrollbar {
	width: 20px;
}

::-webkit-scrollbar-track {
	background-color: #ffc2c4;
}

::-webkit-scrollbar-thumb {
	background-color: #ff0a12;
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #ff8589;
}

::-webkit-scrollbar-thumb:active {
	background-color: #b80006;
	/* Color when the scrollbar thumb is clicked and held */
}

/* Import Google font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
/* font-family: 'Space Mono', monospace; */
@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');
/* font-family: 'Concert One', cursive; */
@import url('https://fonts.googleapis.com/css2?family=Fira+Mono&display=swap');
/* font-family: 'Fira Mono', monospace; */
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
/* font-family: 'Share Tech Mono', monospace; */
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');
/* font-family: 'Black Ops One', cursive; */
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300&display=swap');
/* font-family: 'DM Mono', monospace; */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&family=Wallpoet&display=swap');
/* font-family: 'Space Grotesk', sans-serif; */

* {
	font-family: 'Share Tech Mono', monospace;
}

html {
	background-image: url('./Assets/K_background4.jpg');
	/* margin: -10px; */
}

/* .content {
	opacity: 0;
	transition: opacity 0.1s ease;
}

.content.show {
	opacity: 1;
} */