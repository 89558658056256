.footer {
	display: flex;
	flex-flow: row wrap;
	padding: 30px 30px 20px 30px;
	color: #ffffff;
	background-color: #313131;
	border-top: 1px solid #e5e5e5;
}

.footer > * {
	flex: 1 100%;
}

.footer__addr {
	margin-right: 1.25em;
	margin-bottom: 2em;
}

.footer__logo {
	font-family: 'Pacifico', cursive;
	font-weight: 400;
	text-transform: lowercase;
	font-size: 1.5rem;
}

.footer__addr h2 {
	margin-top: 1.3em;
	font-size: 15px;
	font-weight: 400;
}

.nav__title {
	font-weight: 400;
	font-size: 15px;
}

.footer address {
	font-style: normal;
	color: #999;
}

.footer__btn {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 36px;
	max-width: max-content;
	background-color: #ff6ad512;
	border-radius: 100px;
	color: #f14400;
	line-height: 0;
	margin: 0.6em 0;
	font-size: 1rem;
	padding: 0 1.3em;
}

.footer ul {
	list-style: none;
	padding-left: 0;
}

.footer li {
	line-height: 2em;
}

.footer a {
	text-decoration: none;
}

.footer__nav {
	display: flex;
	flex-flow: row wrap;
}

.footer__nav > * {
	flex: 1 50%;
	margin-right: 1.25em;
}

.nav__ul a {
	color: #999;
}

.nav__ul--extra {
	column-count: 2;
	column-gap: 1.25em;
}

.legal {
	display: flex;
	flex-wrap: wrap;
	color: #999;
}

.legal__links {
	display: flex;
	align-items: center;
}

.heart {
	color: #2f2f2f;
}

@media screen and (min-width: 24.375em) {
	.legal .legal__links {
		margin-left: auto;
	}
}

@media screen and (min-width: 40.375em) {
	.footer__nav > * {
		flex: 1;
	}

	.nav__item--extra {
		flex-grow: 2;
	}

	.footer__addr {
		flex: 1 0px;
	}

	.footer__nav {
		flex: 2 0px;
	}
}

/* FOOTER SOCIAL ICONS: */
.footer-links.inline li {
	display: inline-block;
}
.site-footer .social-icons {
	text-align: right;
}
.site-footer .social-icons a {
	width: 40px;
	height: 40px;
	line-height: 40px;
	margin-left: 6px;
	margin-right: 0;
	border-radius: 100%;
	background-color: #33353d;
}
.copyright-text {
	margin: 0;
}
@media (max-width: 991px) {
	.site-footer [class^='col-'] {
		margin-bottom: 30px;
	}
}
@media (max-width: 767px) {
	.site-footer {
		padding-bottom: 0;
	}
	.site-footer .copyright-text,
	.site-footer .social-icons {
		text-align: center;
	}
}
.social-icons {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.social-icons li {
	display: inline-block;
	margin-bottom: 4px;
}
.social-icons li.title {
	margin-right: 15px;
	text-transform: uppercase;
	color: #96a2b2;
	font-weight: 700;
	font-size: 13px;
}
.social-icons a {
	background-color: #eceeef;
	color: #818a91;
	font-size: 16px;
	display: inline-block;
	line-height: 44px;
	width: 44px;
	height: 44px;
	text-align: center;
	margin-right: 8px;
	border-radius: 100%;
	-webkit-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;

	margin-left: 60px;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
	color: #fff;
	background-color: #29aafe;
	width: 50px;
	height: 50px;
}
.social-icons.size-sm a {
	line-height: 34px;
	height: 34px;
	width: 34px;
	font-size: 14px;
}
.social-icons a.facebook:hover {
	background-color: #3b5998;
}
.social-icons a.twitter:hover {
	background-color: #00aced;
}
.social-icons a.linkedin:hover {
	background-color: #007bb6;
}
.social-icons a.insta:hover {
	background-color: #ff007b;
}
.social-icons a.youtube:hover {
	background-color: #ff0000;
}
.social-icons a.git:hover {
	background-color: #6c6c6c;
}
@media (max-width: 767px) {
	.social-icons li.title {
		display: block;
		margin-right: 0;
		font-weight: 600;
	}
}

.social-icons a {
	display: flex;
	justify-content: center;
	align-items: center;
}
