/* Converted SCSS to CSS */
:root {
	--primary: #da2c43;
	--HOMEtext-color: #333;
}

body {
	margin: 0;
}

.col-12 {
display: flex;
justify-content: center;
align-items: center;
	/* khateeb was here */
}

.date__box {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #ccc;
	border: 4px solid;
	font-weight: bold;
	padding: 5px 10px;
}

.date__box .date__day {
	font-size: 22px;
}

.blog-card {
	padding: 30px;
	position: relative;
	width: 90%;
	/* khateeb was here */
}

.blog-card .date__box {
	opacity: 0;
	transform: scale(0.5);
	transition: 300ms ease-in-out;
}

.blog-card .blog-card__background,
.blog-card .card__background--layer {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.blog-card .blog-card__background {
	padding: 15px;
	background: rgb(172, 172, 172);
}

.blog-card .card__background--wrapper {
	height: 100%;
	clip-path: polygon(0 0, 100% 0, 100% 80%, 0 60%);
	position: relative;
	overflow: hidden;
}

.blog-card .card__background--main {
	height: 100%;
	position: relative;
	transition: 300ms ease-in-out;
	background-repeat: no-repeat;
	background-size: cover;
}

.blog-card .card__background--layer {
	z-index: 0;
	opacity: 0;
	background: rgba(51, 51, 51, 0.9);
	transition: 300ms ease-in-out;
}

.blog-card .blog-card__head {
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.blog-card .blog-card__info {
	z-index: 10;
	background: #fff8f8;
	/* background: rgb(255, 255, 255); */
	padding: 20px 15px;
}

.blog-card .blog-card__info h5 {
	transition: 300ms ease-in-out;
}

.blog-card:hover .date__box {
	opacity: 1;
	transform: scale(1);
}

.blog-card:hover .card__background--main {
	transform: scale(1.2) rotate(5deg);
}

.blog-card:hover .card__background--layer {
	opacity: 1;
}

.blog-card:hover .blog-card__info h5 {
	color: var(--primary);
}

a.icon-link {
	color: var(--HOMEtext-color);
	transition: 200ms ease-in-out;
}

a.icon-link i {
	color: var(--primary);
}

a.icon-link:hover {
	color: var(--primary);
	text-decoration: none;
}

.btn {
	background: white;
	color: var(--HOMEtext-color);
	font-weight: bold;
	outline: none;
	box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
	overflow: hidden;
	border-radius: 0;
	height: 50px;
	line-height: 50px;
	display: inline-block;
	padding: 0;
	border: none;
}

.btn:focus {
	box-shadow: none;
}

.btn:hover {
	background: var(--primary);
	color: #fff;
}

.btn.btn--with-icon {
	padding-right: 20px;
	position: relative;
}

.btn.btn--with-icon:hover {
	animation: pulse-animation 4s infinite;
}

@keyframes pulse-animation {
	0% {
		background-color: white;
	}
	25% {
		background-color: #da2c43;
	}
	50% {
		background-color: #992430; /* Adjust the darker color as needed */
	}
	75% {
		background-color: #da2c43;
	}
	100% {
		background-color: white;
	}
}

.btn.btn--with-icon i {
	padding: 0px 30px 0px 15px;
	margin-right: 10px;
	height: 50px;
	line-height: 50px;
	vertical-align: bottom;
	color: white;
	background: var(--primary);
	clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
}

.btn.btn--only-icon {
	width: 50px;
}

.larger-letter {
	font-size: 1.5em; /* Adjust the font size as needed */
}
