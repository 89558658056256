:root {
	/* Light-Mode Colors: */
	--primary-color: #da2c43;
	--secondary-color: #f9f7f3;
	--trim-color: #420516;
	--textBox-color: #fff;
	--text-color: #f9f7f3;
	--black-text-color: black;
	/* Dark-Mode Colors:*/
	/* --primary-color: #171717;
	--secondary-color: #444444;
	--trim-color: #da2c43;
	--textBox-color: #c9cfca;
	--text-color: #ededed; */
}

.Navbar {
	width: 100%;
}

.navbar-spacer {
	margin-left: 35px;
	border-left: 2px solid #4d4d4d;
	height: 30px;
}

.navbar {
	background-color: #f8f8f8;
	color: #fff;
	position: relative;
	/* left: 5%;
	right: 5%; */
	margin: 10px;
	border-radius: 6px;
	z-index: 999;
	/* width: 100%; khateeb was here */

	/* Initial state */
	transform: translateY(0);
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	transition: transform 0.3s, box-shadow 0.3s;
}

.navbar:hover {
	/* Transformed state */
	transform: translateY(-5px);
	box-shadow: rgba(0, 0, 0, 0.8) 0px 10px 20px;
}

@media (max-width: 767px) {
	.navbar {
		margin: 0;
		border-radius: 0;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		-ms-border-radius: 0;
		-o-border-radius: 0;
	}
}

/* .navbar:hover {
	transform: translateY(-5px);
	box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 10px;
} */
/* 
@media (max-width: 1199px) {
	.navbar {
		min-width: fit-content; khateeb was here
	}
} */

.navbar-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px;
}

.navbar-logo {
	color: var(--text-color);
	text-decoration: none;
	font-size: 20px;
}

.navbar-menu {
	display: flex;
	align-items: center;
}

.navbar-hamburger-menu {
	display: none;
	flex-direction: column;
	cursor: pointer;
}

.navbar-line {
	width: 25px;
	height: 3px;
	background-color: #4d4d4d;
	margin: 3px;
	transition: transform 0.3s;
}

.navbar-nav-links {
	display: flex;
	list-style: none;
	margin-right: 20px;
	margin-left: 20px;
	flex-direction: row;
	align-items: center;
}

.navbar-nav-links li {
	margin-left: 20px;
	/* margin-left: 40px; khateeb was here */
	position: relative;
}

.navbar-nav-links a {
	position: relative;
	color: #4d4d4d;
	text-decoration: none;
	border: none;
	border: 2px solid transparent; /* Add this line for the border style */
	transition: border-color 0.3s; /* Add transition for smooth animation */
	padding: 5px;
	border-radius: 7px;
	font-weight: 600;
}

.navbar-login-out-ID {
	color: var(--text-color);
	text-decoration: none;
	border: none;
	outline: none;
	padding: 5px 20px;
	font-size: 16px;
	background-color: #da2c43;
	border-radius: 6px;
	transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
}

.navbar-login-out-ID a {
	color: white;
}

@media screen and (max-width: 1199px) {
	.navbar-nav-links {
		display: none;
	}

	.navbar-logo-section {
		margin-left: 15px;
	}

	.navbar-hamburger-menu {
		display: flex;
		margin-right: 15px;
	}

	.open .navbar-line:nth-child(1) {
		transform: rotate(-45deg) translate(-5px, 6px);
	}

	.open .navbar-line:nth-child(2) {
		opacity: 0;
	}

	.open .navbar-line:nth-child(3) {
		transform: rotate(45deg) translate(-5px, -6px);
	}

	.open ~ .navbar-nav-links {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #333;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		padding: 10px;
		transition: all 0.3s;
	}
}

@media screen and (max-width: 767px) {
	.navbar {
	}
}

.navbar-nav-links a::before {
	content: '';
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 0;
	height: 2px;
	background-color: var(--primary-color);
	transition: width 0.3s;
}

.navbar-nav-links a:hover::before {
	width: 100%;
}

.navbar-nav-links a.no-hover-animation::before {
	display: none;
}

.navbar-nav-links a.special-link:hover::before {
	width: 100%;
	background-color: #ff9898;
}

.navbar-nav-links a.special-link.signUpLink:hover {
	border-color: var(--primary-color);
}

.navbar-nav-links a.special-link.signUpLink:hover::before {
	width: 0; /* Set the width to 0 to remove the animated line */
}

.special-link:hover .profileIcon {
	color: #832500; /* Change to the desired color on hover */
}

.special-link:hover .settingsIcon {
	color: #b0b0b0; /* Change to the desired color on hover */
} 

.navbar-login-out-ID:hover {
	transform: translate(0, -3px);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	background-color: #ff7676;
}

/* NAVBAR SUBLINKS CSS */

.sublinks-container {
	position: absolute;
	top: 100%;
	left: 0;
	width: 150px;
	z-index: 999;
}

.sublinks {
	list-style: none;
	padding: 5px;
	/* background-color: #ff6666; */
	background: rgba(255, 98, 98, 0.9);
	border-radius: 7px;
}

.sublinks li {
	margin: 5px 0;
}

.sublinks a {
	color: var(--text-color);
	text-decoration: none;
	transition: all 0.3s;
}

.sublinks a:hover {
	color: #ff9898;
}

/* NAVBAR LOGO CSS */
.transparentLogo {
	width: 75px;
	margin-left: 15px;
}

.link-style {
	text-decoration: none;
	color: inherit;
}
