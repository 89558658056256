.zones-TP-page {
	display: flexbox;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	height: 500px;
	padding-left: 170px;
	padding-right: 170px;
}

.zones-TP-table {
	display: flexbox;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	background: rgba(222, 222, 222, 0.8);
}
@media screen and (max-width: 900px) {
	.zones-TP-page {
		padding-left: 0px;
		padding-right: 0px;
	}
}
/* ------------------------------------------------------------------------------------------------------- */

/* .transaction-table {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

table {
	border: 2px solid forestgreen;
	width: 800px;
	height: 200px;
}

th {
	border-bottom: 1px solid black;
}

td {
	text-align: center;
} */
/* ------------------------------------------------------------------------------------------------------- */
/* .container {
	position: absolute;
	top: 50%;
	left: 50%;
	max-height: 600px;
	overflow-y: scroll;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
table {
	width: 800px;
	height: 800px;
	border-collapse: collapse;
	overflow: hidden;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
th,
td {
	padding: 15px;
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff;
}
th {
	text-align: left;
}
thead th {
	background-color: #55608f;
}
tbody tr:hover {
	background-color: rgba(255, 255, 255, 0.3);
}
tbody td {
	position: relative;
}
tbody td:hover:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: -9999px;
	bottom: -9999px;
	background-color: rgba(255, 255, 255, 0.2);
	z-index: -1;
} */

.datagrid {
	margin: 30px;
	background: rgba(222, 222, 222, 0.8);
}
