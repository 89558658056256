  .team-row {
    display: flex;
    flex-wrap: wrap;
    padding: 1em;
    text-align: center;
  }

  .team-column {
    width: 100%;
    padding: 0.5em 0;
  }

  #team-h1 {
    width: 100%;
    text-align: center;
    font-size: 3.5em;
    color: #000;
  }

  .team-card {
    box-shadow: 0 0 2.4em rgba(25, 0, 58, 0.1);
    padding: 3.5em 1em;
    border-radius: 0.6em;
    color: #000;
    cursor: pointer;
    transition: 0.3s;
    background-color: #ffffff;
  }

  .team-card .img-container {
    width: 8em;
    height: 8em;
    background-color: #da2c43;
    padding: 0.5em;
    border-radius: 50%;
    margin: 0 auto 2em auto;
  }

  .team-card img {
    width: 50%;
    border-radius: 50%;
  }

  .team-card h3 {
    font-weight: 500;
  }

  .team-card p {
    font-weight: 300;
    text-transform: uppercase;
    margin: 0.5em 0 2em 0;
    letter-spacing: 2px;
  }

  .team-icons {
    width: 50%;
    min-width: 180px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }

  .team-card a {
    text-decoration: none;
    color: inherit;
    font-size: 1.4em;
  }

  .team-card:hover {
    background-image: linear-gradient(to bottom, #ff8e9f, #da2c43);
    color: #ffffff;
  }

  .team-card:hover .img-container {
    transform: scale(1.15);
  }

  @media screen and (min-width: 768px) {
    .team-section {
      padding: 1em 7em;
    }
  }

  @media screen and (min-width: 992px) {
    .team-section {
      padding: 1em;
    }

    .team-card {
      padding: 3.5em 0.6em;
    }

    .team-column {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 1em;
    }
  }