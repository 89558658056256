:root {
	/* Light-Mode Colors: */
	--primary-color: #da2c43;
	--secondary-color: #f4f9f3;
	--trim-color: #da2c43;
	--textBox-color: #fff;
	--text-color: #f4f9f3;
	/* Dark-Mode Colors:*/
	/* --primary-color: #171717;
	--secondary-color: #444444;
	--trim-color: #da2c43;
	--textBox-color: #c9cfca;
	--text-color: #ededed; */
}

/* body {
	background-image: url('../../Assets/5418748.jpg');
} */

.RR-page-body {
	height: 500px;
}

.RRcontainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;

	margin: 5vh auto; /* Center the container vertically and horizontally */
	background: #924b00;
	padding: 25px;
	border-radius: 8px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

	/* i added this extra so the login isnt so small */
	width: 620px;
	height: 270px;

	transition: 0.3s;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
}
/* I also added this part. for the login width */
/* Media query for screens smaller than 400px */
@media (max-width: 1199px) {
	.RRcontainer {
		min-width: 100%; /* Adjust the width for smaller screens */
		margin: 5vh auto; /* Center the container vertically and horizontally */
	}
}

.RRcontainer:hover {
	background: #ff474e;
	transition: 0.3s;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	/* ALSO ON CLICK. POP UP WINDOW ASK IF REFUND */
}

header {
	font-size: 2rem;
	color: #333;
	font-weight: bolder;
	text-align: center;
	margin: 20px;
}
.RRcontainer .RRform {
	margin-top: 30px;
}
.RRform .RRinput-box {
	width: 100%;
	margin-top: 20px;
}
.RRinput-box .labels {
	display: flex;
	color: #333;
}
.RRinput-box input {
	background-color: var(--textBox-color);
}
.RRform :where(.RRinput-box input, .RRselect-box) {
	position: relative;
	height: 50px;
	width: 95%;
	outline: none;
	font-size: 1rem;
	color: #707070;
	margin-top: 8px;
	border: 1px solid #dddddd;
	border-radius: 6px;
	padding: 0 15px;
}

.RRform button {
	height: 55px;
	width: 100%;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	margin-top: 30px;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease;
	background: var(--primary-color);
}

.ticket-instance {
	position: relative;
	width: 50%; /* Subtract the margin from the total width */
	max-width: 500px;
	margin: 5vh auto; /* Center the container vertically and horizontally */
	background: var(--primary-color);
	padding: 25px;
	border-radius: 8px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

	/* i added this extra so the login isnt so small */
	min-width: 1000px;
}

/* RATING SCALE CSS */

.RRratingScale {
	margin-left: 430px;
	margin-top: 300px;
}
