:root {
	/* Light-Mode Colors: */
	--GT-primary-color: #da2c43;
	--GT-secondary-color: #f9f7f3;
	--GT-trim-color: #da2c43;
	--GT-textBox-color: #fff;
	--GT-text-color: #333;
	/* Dark-Mode Colors:*/
	/* --primary-color: #171717;
	--secondary-color: #444444;
	--trim-color: #da2c43;
	--textBox-color: #c9cfca;
	--text-color: #ededed; */
}
.GT-page {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80vh;
	/* padding: 50px;  khateeb was here*/
}
.GT-container {
	position: relative;
	max-width: 700px;
	width: 100%;
	background: var(--GT-secondary-color);
	padding: 25px;
	border-radius: 8px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

	/* i added this extra so the login isnt so small */
	min-width: 700px;
}
/* I also added this part. for the login width */
/* Media query for screens smaller than 400px */
@media (max-width: 700px) {
	.GT-container {
		min-width: auto; /* Remove the minimum width */
	}
}
.GT-container header {
	font-size: 1.5rem;
	color: var(--GT-text-color);
	font-weight: 500;
	text-align: center;
}
.GT-container .GT-form {
	margin-top: 30px;
}
.GT-form .GT-input-box {
	width: 100%;
	margin-top: 20px;
}
.GT-input-box label {
	color: var(--GT-text-color);
}
.GT-input-box input {
	background-color: var(--GT-textBox-color);
}
.GT-form :where(.GT-input-box input, .select-box) {
	position: relative;
	height: 50px;
	width: 100%;
	outline: none;
	font-size: 1rem;
	color: #707070;
	margin-top: 8px;
	border: 1px solid #dddddd;
	border-radius: 6px;
	padding: 0 15px;
}
.GT-input-box input:focus {
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.GT-form .GT-column {
	display: flex;
	column-gap: 15px;
}

.GT-form button {
	height: 55px;
	width: 100%;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	margin-top: 30px;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease;
	background: var(--GT-primary-color);
}
.GT-form button:hover {
	background: var(--GT-primary-color);
}
/*Responsive*/
@media screen and (max-width: 500px) {
	.GT-form .GT-column {
		flex-wrap: wrap;
	}
}

/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------- */
/* GET TICKET SECTION BOX CSS */
.Get-Ticket-Whole-Page {
	/* display: flexbox;
	align-items: center;
	justify-content: center;
	flex-direction: column; */
/* 
	height: 340px;
	padding: 50px; khateeb was here*/
	margin: 0 10px;
}

.GTSection1 {
	display: flex;
	align-items: center;
	justify-content: center;
}

.GTSection1 .GTBoxPickup {
	-webkit-box-shadow: 0px 3px 11px 0px rgba(117, 117, 117, 1);
	-moz-box-shadow: 0px 3px 11px 0px rgba(117, 117, 117, 1);
	box-shadow: 0px 3px 11px 0px rgba(117, 117, 117, 1);
	padding: 15px;
	width: 750px;
	background-color: var(--secondary-color);
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

@media (max-width: 767px) {
	.GTSection1 .GTBoxPickup {
		width: 100%;
	}
}

.GTSection1 .GTBoxPickup p {
	font-weight: bold;
	margin: 10px 20px;
}

.GTSection1 .GTBoxPickup .GTBoxFromTo {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 20px 0;
}

.GTSection1 .GTBoxPickup .GTBoxFromTo i {
	transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
}


@media (max-width: 1200px) {
	.GTSection1 .GTBoxPickup .GTBoxFromTo i {
		font-size: 10px;

	}
}

.GTSection1 .GTBoxPickup .GTBoxFromTo input {
	width: 40%;
	height: 45px;
	background-color: #f0f3f5;
	border: 0;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
	padding: 10px;
	outline: 0;
}

.GTSection1 .GTBoxPickup .GTBoxFromTo input:focus {
	border: 2px solid#D52941;
}

.GTSection1 .GTBoxPickup .GTBoxFromTo input:focus::placeholder {
	color: black;
	font-weight: bold;
}

@media (max-width: 1199px) {
	.GTSection1 .GTBoxPickup .GTBoxFromTo input{
		width: 80px;
		padding: 0;
		margin: 0;
	}
}
.GTSection1 .GT-box-pickup .GTBoxDate {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}

.GTSection1 .GTBoxPickup .GTBoxDate input {
	width: 40%;
	margin: 5px 20px;
	height: 45px;
	padding: 5px;
	background-color: #f0f3f5;
	border: 0;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
	padding: 10px;
	outline: 0;
}

.GTSection1 .GTBoxPickup .GTBoxDate input:focus {
	border: 2px solid #d52941;
}

.GTSection1 .GTBoxPickup .GTBoxDate input:focus::placeholder {
	color: black;
	font-weight: bold;
}

.GTSection1 a {
	display: block;
	margin-left: auto;
	width: fit-content;
	text-decoration: none;
	color: white;
	background-color: #d52941;
	padding: 10px 15px;
	border-radius: 6px;
	transition: 0.3s;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
	margin-top: 8px;
}

.GTSection1 a:hover {
	background-color: rgb(162, 50, 65);
}

/* REMEMBER PAYMENT INFO SWITCH CSS */
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
/* font-family: 'Share Tech Mono', monospace; */

.priceIcon {
	transition: color 2s, transform 2s;
}

.priceIcon:hover {
	color: #079220;
	transform: scale(1.2);
}
