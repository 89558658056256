.request-TP-page {
	display: flexbox;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	height: 500px;
	padding-left: 150px;
	padding-right: 150px;
}

.request-TP-table {
	display: flexbox;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	background: rgba(222, 222, 222, 0.8);
}

@media screen and (max-width: 900px) {
	.request-TP-page {
		padding-left: 0px;
		padding-right: 0px;
	}
}

/* ------------------------------------------------------------------------------------------------------- */

/* .transaction-table {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

table {
	border: 2px solid forestgreen;
	width: 800px;
	height: 200px;
}

th {
	border-bottom: 1px solid black;
}

td {
	text-align: center;
} */
/* ------------------------------------------------------------------------------------------------------- */
/* .container {
	position: absolute;
	top: 50%;
	left: 50%;
	max-height: 600px;
	overflow-y: scroll;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
table {
	width: 800px;
	height: 800px;
	border-collapse: collapse;
	overflow: hidden;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
th,
td {
	padding: 15px;
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff;
}
th {
	text-align: left;
}
thead th {
	background-color: #55608f;
}
tbody tr:hover {
	background-color: rgba(255, 255, 255, 0.3);
}
tbody td {
	position: relative;
}
tbody td:hover:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: -9999px;
	bottom: -9999px;
	background-color: rgba(255, 255, 255, 0.2);
	z-index: -1;
} */

.AMRmodal {
	position: fixed; /* Make the modal fixed position */
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999; /* Set a higher z-index to make it appear in front */
	background: rgba(0, 0, 0, 0.2); /* Add a semi-transparent background for overlay effect */

	width: 100%;
}

.AMRmodal .AMRoverlay {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.AMRmodal .AMR-modal-form {
	max-width: 700px;
	width: 100%;
	background: var(--VS-secondary-color);
	padding: 25px;
	border-radius: 8px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	/* Removed the min-width property */

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.AMRmodal header {
	font-size: 1.5rem;
	color: var(--VS-text-color);
	font-weight: 500;
	text-align: center;
}

.AMR-modal-form .input-box {
	width: 100%;
	margin-top: 20px;
}
.AMR-modal-form :where(.input-box input, .select-box) {
	position: relative;
	height: 50px;
	width: 100%;
	outline: none;
	font-size: 1rem;
	color: #707070;
	margin-top: 8px;
	border: 1px solid #dddddd;
	border-radius: 6px;
	padding: 0 15px;
}

.div .AMRmodal-Refundcolumn {
	width: 1000px;
}
.AMR-modal-form button {
	height: 55px;
	width: 160px;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease;
	background: var(--VS-primary-color);
	padding: 15px;
	margin-left: 20px;
	margin-right: 20px;

	transition: 0.3s;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
}

.AMR-modal-form button:hover {
	background-color: rgb(156, 30, 30);
}
