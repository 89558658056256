.projcard-container {
	margin: 50px auto;
	max-width: 1000px;
}

.projcard-container .projcard-blue {
	margin-left: 60px;
}

.projcard-container .projcard-green {
	margin-left: 60px;
}

/* Responsive Adjustments */
@media (max-width: 1199px) {
	.projcard-container {
		max-width: 800px;
	}

	.projcard-img {
		display: none;
	}

	.projcard-innerbox .projcard-textbox {
		position: static;
		width: 100%;
		padding: 20px;
	}

	.projcard-container .projcard-tagbox {
		display: flex;
		flex-direction: column;
		margin-bottom: 10px;
	}

	.projcard-container .projcard-tagbox .projcard-tag:not(:last-child) {
		margin-bottom: 10px;
	}
}

@media (max-width: 992px) {
	.projcard-container {
		max-width: 600px;
	}

	.projcard-img {
		display: none;
	}
}

@media (max-width: 768px) {
	.projcard-container .projcard {
		margin: 20px 0;
		height: 350px;
	}
	.projcard-container {
		max-width: 400px;
	}

	.projcard-img {
		display: none;
	}
}

.projcard {
	position: relative;
	width: 100%;
	height: 300px;
	margin: 0;
	margin-bottom: 40px;
	border-radius: 10px;
	background-color: #fff;
	border: 2px solid #ddd;
	font-size: 18px;
	overflow: hidden;
	cursor: pointer;
	box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
	transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.projcard:hover {
	box-shadow: 0 34px 32px -33px rgba(0, 0, 0, 0.18);
	transform: translate(0px, -3px);
}

.projcard::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(-70deg, #424242, transparent 50%);
	opacity: 0.07;
}

.projcard:nth-child(2n)::before {
	background-image: linear-gradient(-250deg, #424242, transparent 50%);
}

.projcard-innerbox {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.projcard-img {
	position: absolute;
	height: 300px;
	width: 400px;
	top: 0;
	left: 0;
	transition: transform 0.2s ease;
}

.projcard:nth-child(2n) .projcard-img {
	left: initial;
	right: 0;
}

.projcard:hover .projcard-img {
	transform: scale(1.05) rotate(1deg);
}

.projcard:hover .projcard-bar {
	width: 70px;
}

.projcard-textbox {
	position: absolute;
	top: 7%;
	bottom: 7%;
	left: 430px;
	width: calc(100% - 470px);
	font-size: 17px;
}

.projcard:nth-child(2n) .projcard-textbox {
	left: initial;
	right: 430px;
}

.projcard-textbox::before,
.projcard-textbox::after {
	content: '';
	position: absolute;
	display: block;
	background: #ff0000bb;
	background: #fff;
	top: -20%;
	left: -55px;
	height: 140%;
	width: 60px;
	transform: rotate(8deg);
}

.projcard:nth-child(2n) .projcard-textbox::before {
	display: none;
}

.projcard-textbox::after {
	display: none;
	left: initial;
	right: -55px;
}

.projcard:nth-child(2n) .projcard-textbox::after {
	display: block;
}

.projcard-textbox * {
	position: relative;
}

.projcard-title {
	font-family: 'Voces', 'Open Sans', arial, sans-serif;
	font-size: 24px;
}

.projcard-subtitle {
	font-family: 'Voces', 'Open Sans', arial, sans-serif;
	color: #888;
}

.projcard-bar {
	left: -2px;
	width: 50px;
	height: 5px;
	margin: 10px 0;
	border-radius: 5px;
	background-color: #424242;
	transition: width 0.2s ease;
}

.projcard-blue .projcard-bar {
	background-color: #0088ff;
}

.projcard-blue::before {
	background-image: linear-gradient(-70deg, #0088ff, transparent 50%);
}

.projcard-blue:nth-child(2n)::before {
	background-image: linear-gradient(-250deg, #0088ff, transparent 50%);
}

.projcard-red .projcard-bar {
	background-color: #d62f1f;
}

.projcard-red::before {
	background-image: linear-gradient(-70deg, #d62f1f, transparent 50%);
}

.projcard-red:nth-child(2n)::before {
	background-image: linear-gradient(-250deg, #d62f1f, transparent 50%);
}

.projcard-green .projcard-bar {
	background-color: #40bd00;
}

.projcard-green::before {
	background-image: linear-gradient(-70deg, #40bd00, transparent 50%);
}

.projcard-green:nth-child(2n)::before {
	background-image: linear-gradient(-250deg, #40bd00, transparent 50%);
}

.projcard-yellow .projcard-bar {
	background-color: #f5af41;
}

.projcard-yellow::before {
	background-image: linear-gradient(-70deg, #f5af41, transparent 50%);
}

.projcard-yellow:nth-child(2n)::before {
	background-image: linear-gradient(-250deg, #f5af41, transparent 50%);
}

.projcard-orange .projcard-bar {
	background-color: #ff5722;
}

.projcard-orange::before {
	background-image: linear-gradient(-70deg, #ff5722, transparent 50%);
}

.projcard-orange:nth-child(2n)::before {
	background-image: linear-gradient(-250deg, #ff5722, transparent 50%);
}

.projcard-brown .projcard-bar {
	background-color: #c49863;
}

.projcard-brown::before {
	background-image: linear-gradient(-70deg, #c49863, transparent 50%);
}

.projcard-brown:nth-child(2n)::before {
	background-image: linear-gradient(-250deg, #c49863, transparent 50%);
}

.projcard-grey .projcard-bar {
	background-color: #424242;
}

.projcard-grey::before {
	background-image: linear-gradient(-70deg, #424242, transparent 50%);
}

.projcard-grey:nth-child(2n)::before {
	background-image: linear-gradient(-250deg, #424242, transparent 50%);
}

.projcard-customcolor .projcard-bar {
	background-color: var(--projcard-color);
}

.projcard-customcolor::before {
	background-image: linear-gradient(-70deg, var(--projcard-color), transparent 50%);
}

.projcard-customcolor:nth-child(2n)::before {
	background-image: linear-gradient(-250deg, var(--projcard-color), transparent 50%);
}

.projcard-description {
	z-index: 10;
	font-size: 15px;
	color: #424242;
	height: 125px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.projcard-tagbox {
	position: absolute;
	bottom: 3%;
	font-size: 14px;
	cursor: default;
	user-select: none;
	pointer-events: none;
}

.projcard-tag {
	display: inline-block;
	background: #e0e0e0;
	color: #777;
	border-radius: 3px 0 0 3px;
	line-height: 26px;
	padding: 0 10px 0 23px;
	position: relative;
	margin-right: 20px;
	cursor: default;
	user-select: none;
	transition: color 0.2s;
}

.projcard-tag::before {
	content: '';
	position: absolute;
	background: #fff;
	border-radius: 10px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
	height: 6px;
	left: 10px;
	width: 6px;
	top: 10px;
}

.projcard-tag::after {
	content: '';
	position: absolute;
	border-bottom: 13px solid transparent;
	border-left: 10px solid #e0e0e0;
	border-top: 13px solid transparent;
	right: -10px;
	top: 0;
}

.responsive-img {
	max-width: 100%;
	height: auto;
}

.projcard-img {
	position: absolute;
	height: 300px;
	width: 400px;
	top: 0;
	left: 0;
	transition: transform 0.2s ease;
	object-fit: cover;
	/* Add this line */
}